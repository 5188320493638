/* variables.scss */

// Colors
$color-primary: #111111;
$color-secondary: #fffff8;
$color-burnt-orange: #cc5500;
$color-pale-teal: #52b3b6;
$color-chrome-grey: #dbe2e9;
$color-storm-grey: #b5bab6;
$color-black: #000000;
$color-white: #ffffff;

// Fonts
$font-primary: 'Inter', sans-serif;
$font-secondary: 'Lora', sans-serif;

// Font Sizes
$font-size-xs: 1.2rem;
$font-size-sm: 1.4rem;
$font-size-md: 1.6rem;
$font-size-lg: 1.8rem;
$font-size-xl: 2.0rem;
$font-size-xxl: 2.4rem;

// Font Weights
$font-weight-thin: 100;
$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

// Spacing
$spacing-xs: 1rem;
$spacing-sm: 2rem;
$spacing-md: 3rem;
$spacing-lg: 4rem;
$spacing-xl: 5rem;
$spacing-xxl: 6rem;

// Z-Index
$z-index-header: 100;
$z-index-modal: 200;
$z-index-overlay: 300;
$z-index-dropdown: 400;
$z-index-sticky: 500;
$z-index-fixed: 600;

// Breakpoints
$breakpoint-xs: 20rem;
$breakpoint-sm: 36rem;
$breakpoint-md: 48rem;
$breakpoint-lg: 62rem;
$breakpoint-xl: 75rem;
$breakpoint-xxl: 87.5rem;