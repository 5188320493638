@use 'variables'as *;
@use 'mixins'as *;
@use 'sass:color';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font-primary;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  scroll-behavior: smooth;
  width: 100%;
}

.footer {
  margin-top: auto;
}

body {
  padding: 1rem;
  line-height: 1.6;
  background-color: $color-secondary;
  color: $color-primary;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-align: left;
  line-height: 1.15;
}

.about h3,
.career h3,
.clients h3,
.methodologies h3,
.publications h3 {
  margin-top: 5rem;
}

.notes h2 {
  margin-top: 10rem;
}

/* Responsive h1 */
h1 {
  margin-top: 7rem;
  margin-bottom: 0.5rem;
  font-size: $font-size-xxl;
  font-weight: $font-weight-black;

  @media (min-width: 320px) {
    font-size: calc($font-size-xxl * 1.3);
  }

  @media (min-width: 480px) {
    font-size: calc($font-size-xxl * 1.5);
  }

  @media (min-width: 768px) {
    font-size: calc($font-size-xxl * 1.4);
  }

  @media (min-width: 1024px) {
    font-size: calc($font-size-xxl * 1.8);
  }
}

h2 {
  margin-bottom: 1rem;
  font-size: $font-size-xl;
  font-weight: $font-weight-medium;

  @media (min-width: 320px) {
    font-size: calc($font-size-xl * 1);
  }

  @media (min-width: 480px) {
    font-size: calc($font-size-xl * 1.2);
  }

  @media (min-width: 768px) {
    font-size: calc($font-size-xl * 1.3);
  }

  @media (min-width: 1024px) {
    font-size: calc($font-size-xl * 1.4);
  }
}

h3 {
  margin-bottom: 1rem;
  font-size: $font-size-lg;
  font-weight: $font-weight-medium;
  color: $color-black;

  @media (min-width: 320px) {
    font-size: calc($font-size-lg * 1);
  }

  @media (min-width: 480px) {
    font-size: calc($font-size-lg * 1.2);
  }

  @media (min-width: 768px) {
    font-size: calc($font-size-lg * 1.3);
  }

  @media (min-width: 1024px) {
    font-size: calc($font-size-lg * 1.4);
  }
}

h4 {
  margin-bottom: 1rem;
  font-size: $font-size-md;
  font-weight: $font-weight-normal;
  color: $color-primary;

  @media (min-width: 320px) {
    font-size: calc($font-size-md * 1);
  }

  @media (min-width: 480px) {
    font-size: calc($font-size-md * 1.2);
  }

  @media (min-width: 768px) {
    font-size: calc($font-size-md * 1.3);
  }

  @media (min-width: 1024px) {
    font-size: calc($font-size-md * 1.4);
  }
}

/* Container Utility */
.container {
  padding: 0;
  width: 100%;

  @media (min-width: $breakpoint-md) {
    max-width: 63ch;
    margin: 0 auto;
  }
}

/* Lists & Misc */
ul {
  list-style: none;
}

li {
  margin-bottom: 0.425rem;
}

ul.career li,
section.github li {
  list-style: disc;
}

.about li {
  margin-bottom: 1rem;
}

strong {
  font-weight: $font-weight-medium;
}

.about strong,
.career strong {
  font-weight: $font-weight-bold;
}

ul.career strong {
  font-weight: $font-weight-medium;
}

a {
  color: $color-primary;
  text-decoration: underline;
}

a.apb {
  color: $color-burnt-orange;
  text-decoration: underline;
}

.footer p {
  font-size: calc($font-size-xs * 0.65);
  font-weight: $font-weight-light;
  color: $color-black;
}

figure {
  padding: 0;
  margin: 0;
}

figcaption {
  font-size: calc($font-size-xs * 0.65);
  font-weight: $font-weight-normal;
  color: $color-storm-grey;
  text-align: left;
}

#updateDate b {
  font-weight: $font-weight-normal;
}

.LT_title {
  font-weight: $font-weight-normal;
  color: $color-pale-teal;
}

.LT_author {
  color: $color-storm-grey;
}

p.more {
  margin-top: 7rem;
  margin-bottom: 0;
  font-size: $font-size-md;
  font-weight: $font-weight-light;
  color: $color-primary;

  @media (min-width: 320px) {
    font-size: calc($font-size-md * 1);
  }

  @media (min-width: 480px) {
    font-size: calc($font-size-md * 1.2);
  }

  @media (min-width: 768px) {
    font-size: calc($font-size-md * 1.3);
  }

  @media (min-width: 1024px) {
    font-size: calc($font-size-md * 1.4);
  }
}

h1.apb {
  margin-top: 0;
  margin-bottom: 1rem;

  @media (min-width: 320px) {
    font-size: calc($font-size-xxl * 1.3);
  }

  @media (min-width: 480px) {
    font-size: calc($font-size-xxl * 1.5);
  }

  @media (min-width: 768px) {
    font-size: calc($font-size-xxl * 1.4);
  }

  @media (min-width: 1024px) {
    font-size: calc($font-size-xxl * 1.8);
  }
}

.now h2,
.inout h2,
.linkedin h2,
.github h2,
.genai h2,
.side h2,
.creative h2,
.library h2,
.whereabout h2,
.reading h2,
.contact h2,
.robot h2 {
  margin-top: 5rem;
}

.reveal {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.reveal.visible {
  opacity: 1;
  transform: translateY(0);
}

.striken {
  text-decoration: line-through;
}

sup {
  text-decoration: none !important;
}

// resume styles

p.download-cv {
  padding-top: 2rem;
  margin-bottom: 5rem;
}

p.resume-intro{
  text-align: justify;
}

h2.resume,
h2.exp-areas,
h2.pro-exp,
h2.cert,
h2.edu,
h2.methods,
h2.industries,
h2.agencies,
h2.languages,
h2.keywords {
  margin-top: 3rem;
}

h2.add-info {
  margin-top: 5rem;
}

h3.exp-latest,
h3.exp-recent,
h3.smart-city,
h3.AI-inno,
h3.privacy-data,
h3.workshops,
h3.teams {
  margin-top: 2rem;
  font-weight: $font-weight-light;
  color: $color-burnt-orange;
}

h4.urban,
h4.agentic,
h4.AI-inno,
h4.privacy-data,
h4.worskshops,
h4.teams {
  font-weight: $font-weight-light;
  color: color.adjust($color-primary, $lightness: 30%);
}

ul.exp-areas li, ul.methods, ul.cert, ul.agencies, ul.languages {
  list-style-type: disc;
} 

ul li::marker, ul li.methods::marker, ul.cert li::marker, ul.agencies li::marker, ul.langages li::marker {
    color: $color-burnt-orange;
  }

ul.edu, ul.edu li, ul.industries, ul.industries li, ul.add-info, ul.add-info li {
  padding-left: 0;
}

ul.keywords, ul.keywords li {
  padding-left: 0;
}

ul.keywords li {
  display: inline;
  padding-right: .3rem;
}

p.highlights-exp {
  padding: .5rem;
  margin-bottom: 3rem;
  background-color: color.adjust($color-burnt-orange, $lightness: 35%);
}

p.employer {
  color: color.adjust($color-black, $lightness: 35%);
}

p.consideration {
  margin-top: 10rem;
  color: color.adjust($color-primary, $lightness: 50%);
  font-size: calc($font-size-xs / 1.35);
  text-align: right;
}
