@use 'variables' as *;

/* Header with Hamburger Menu */
.header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  background-color: $color-secondary;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  z-index: $z-index-header;

  .menu-button {
    background: none;
    border: none;
    margin: 0;
    padding: 1rem 1rem 1rem .3rem; // Increase the clickable area around the icon
    display: inline-block; // Ensure the anchor occupies a block area
    text-decoration: none;
    cursor: pointer;

    .menu-icon {
      position: relative;
      width: 24px;
      height: 2px;
      background-color: $color-burnt-orange;
      
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 24px;
        height: 2px;
        background-color: $color-burnt-orange;
      }
      
      &::before {
        top: -6px;
      }
      
      &::after {
        top: 6px;
      }
    }
  }
  /* Modifier: Turn burger into an X when the active class is applied */
  .menu-button.active .menu-icon {
    background-color: transparent; // hide the middle line
  }
  .menu-button.active .menu-icon::before {
    transform: translateY(6px) rotate(45deg);
    background-color: $color-primary;
  }
  .menu-button.active .menu-icon::after {
    transform: translateY(-6px) rotate(-45deg);
    background-color: $color-primary;
  }
}

/* Hero Section */
.hero {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  background-color: $color-secondary;
  min-height: auto;
  
  .container {
    padding: 0;
    width: 100%;
  }
  
  .quote {
    margin: 5rem 0;
    padding: 0;
    font-size: $font-size-md;
    font-family: $font-secondary;
    line-height: 1.4;
    color: $color-pale-teal;
    
    @media (min-width: $breakpoint-md) {
      max-width: 63ch;
    }
    
    p {
      margin-bottom: 0.5rem;
    }
    
    footer {
      font-size: $font-size-sm;
      color: $color-storm-grey;
    }
  }
}

.notes {
  .quote {
    margin: 5rem 0;
    padding: 0;
    font-size: $font-size-md;
    font-family: $font-secondary;
    line-height: 1.4;
    color: $color-pale-teal;
    
    @media (min-width: $breakpoint-md) {
      max-width: 63ch;
    }
    
    p {
      margin-bottom: 0.5rem;
    }
    
    footer {
      font-size: $font-size-sm;
      color: $color-storm-grey;
    }
  }
}

/* Header text container: stack h2 and p vertically */
.header-text {
  display: block;
  margin-bottom: 1rem;
  
  
  p {
    margin: 0;
  }
}

/* Carousel */
.carousel-wrapper {
  position: relative; /* If you need absolute-positioned elements inside */
}

.carousel {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 0;

  img {
    flex-shrink: 0;
    width: 300px; // Adjust as needed
    height: auto;
    scroll-snap-align: start;
    border-radius: 4px;
  }
}

/* --- Desktop Arrow Controls --- */
.carousel-controls {
  display: flex;
  justify-content: flex-end;
  gap: $spacing-xs; // 1rem
  margin-top: $spacing-xs;
}

.arrow {
  width: 36px;
  height: 36px;
  // Use a background that’s 50% lighter than $color-chrome-grey
  background-color: $color-chrome-grey;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-sm;  // e.g. 1.4rem
  font-weight: $font-weight-medium; // heavy black
  font-family: $font-primary;
  line-height: 1;           // ensure the arrow glyph is centered
  color: $color-black;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  
  &:hover {
    color: $color-burnt-orange;
  }
}

/* Inactive state: when there’s no room to scroll */
.arrow.inactive {
  opacity: 0.3;
  pointer-events: none;
}

/* --- Dot Styles --- */
.carousel-dots {
  display: none;               // hidden by default (desktop)
  justify-content: center;
  margin-top: $spacing-xs;

  .dot {
    width: 8px;
    height: 8px;
    background-color: $color-chrome-grey;
    border-radius: 50%;
    margin: 0 5px;
    display: inline-block;
  }

  .dot.active {
    background-color: $color-burnt-orange;
  }
}

/* --- Mobile vs Desktop --- */
/* Show dots on mobile, hide arrow controls */
@media (max-width: $breakpoint-md) {
  .carousel-controls {
    display: none;
  }
  .carousel-dots {
    display: flex; // now visible on mobile
  }
}

/* Show arrow controls on desktop, hide dots */
@media (min-width: $breakpoint-md) {
  .carousel-dots {
    display: none;
  }
}
