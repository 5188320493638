@use 'variables' as *;
@use 'mixins' as *;

section {
  width: 100%;
  
  /* For mobile, ensure each section snaps */
  @media (max-width: $breakpoint-md) {
    scroll-snap-align: start;
  }
}

/* Ensure the .about section has the same max-width as the hero container */
.header, .hero, .about, .career, .clients, .methodologies, .publications, .footer, .now, .inout, .linkedin, .github, .genai, .side, .creative, .library, .work, .whereabout, .reading, .contact, .robot, .notes, .case-study, .case-image {
  margin: 0 auto; // centers the section on larger screens
  width: 100%;
  
  @media (min-width: $breakpoint-md) {
    max-width: 63ch; // same width as the hero's inner container
  }
}

.career h4 {
  margin-top: 1rem;
}
.career p {
  margin-bottom: 0;
  margin-top: 0;
}

.career p.next {
  margin-top: 1rem;
}

.publications img {
  margin-top: 2rem;
}

.footer {
  margin-top: 5rem;
  padding-bottom: .5rem;
}

// LibraryThing
.LT_header, .LT_Preload, .LT_powered {
  display: none;
}

.LT_itemLast {
 margin-bottom: .5rem;
}